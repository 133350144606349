<!-- mini audio player for bottom of screen -->

<template>
  <div class="mini-player w-full bg-blue-50 "  :class="{ 'h-full': modal, 'pt-16':modal, 'border-t-2': !modal, 'grid': !modal, 'grid-cols-6': !modal }"  autoplay >

    
    <button v-if="modal" @click="$emit('close')" class="bx bx-md bx-chevron-down left-5 absolute top-16 text-blue-900 z-10"></button>

    <audio-player :class="modal ? 'fixed bottom-20 w-full bg-slate-50' : 'pt-4 col-span-6 float-right'" :url="songData.downloadUrl ? songData.downloadUrl : ''" :name="songData.name ? songData.name : ''" :imgUrl="songData.image ? songData.image : '@/assets/logo.png'"
    playerid="audio-player" :track-playing="trackPlaying" :full-screen-player="fullScreenPlayer" :modal="modal" :skip-track="skipTrack" :is-first-song="songData.trackFolderIndex===0" :is-last-song="songData.trackFolderIndex+1 >= songData.albumLength"> </audio-player>
  </div>
</template>

 
 

<script>
import AudioPlayer from '@/components/AudioPlayer.vue';
export default {
  props: {
    songData: {
      type: Object,
      required: true
    },
    closeTrack: {
      type: Function,
      required: true
    },
    fullScreenPlayer: {
      type: Function,
      required: false
    },
    trackPlaying: {
      type: Boolean,
      required: false
    },
    modal: {
      type: Boolean,
      required: true
    },
    skipTrack: {
      type: Function,
      required: true
    }
  },
  components: {AudioPlayer},
}
</script>
<style>
</style>