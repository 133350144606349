import { store } from "@/stores/store";

class ApiService {
    constructor() {
        this.jli_api_base_url = process.env.VUE_APP_API_BASE;
        // 'https://www.myjli.com/reg-system-api/api.php';
        this.resource_api_base_url = process.env.VUE_APP_RESOURCE_API_BASE
        // 'https://resources.myjli.com/api/'
    }
    
    async login(username, password, activeSite=1) {
        let retried = false;
        let loginStatus = {
            success: false,
            errorMessage: ''
        }
        if(!username || !password) {
            loginStatus.errorMessage = 'Username or Password missing';
            return loginStatus;
        }
        // need to hash password
        this.postBody = {username,password,activeSite};
        loginStatus = await this.apiCall(this.postBody, loginStatus);

        if (!loginStatus && !retried) {
            loginStatus = await this.apiCall(this.postBody, loginStatus);
            retried=true;
        }
        if (!loginStatus && retried) {
            return  {
                success: false,
                errorMessage: 'Error. Please Try Again'
            }
        }
    //    check if response with errormessage returns properly - with error as ''errorMessage'
        return loginStatus
    }


    logout() {
        store.clear();
        localStorage.clear();
    }


    async authenticateUser(userId, activeSite=1) {
        // api call to jli to verify, return true / false
        const loginStatus = await this.apiCall({"userId": userId, "activeSite": activeSite}, {success: false,errorMessage:''});
        return loginStatus;
    }

    async apiCall(requestBody={}, loginStatus) {
         // api call , if error return error message
        // if successful set data in storage
        // try {
            const response = await this.fetchWithTimeout(this.jli_api_base_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                // timeout: 8000,
                body: JSON.stringify(requestBody)
            });
            
            if (!response || !response.ok ) {
                switch (response.status) {
                    case 401:
                        loginStatus.errorMessage = 'Unauthorized login.';
                        break;
                    case 500:
                        loginStatus.errorMessage = 'Server Error, please try again soon'
                        break;
                    default:
                        loginStatus.errorMessage = 'Unknown Error, please try again soon'
                        break;
                }
                
                return loginStatus;
            }
            loginStatus.success = true;

            const json = await response.json();

            // should move to external func
            store.userId = json.userId;
            localStorage.userId = json.userid;
            store.username = json.username;
            localStorage.username = json.username;
            store.isAuthenticated = true
            /**
             * 1 = FS
             * 2 = TS
             * 3 = SSS
             * ...etc
             *  */
            // currently only rcs and fs allowed
            localStorage.setItem("allowedSites", JSON.stringify(json.allowedSites.filter(site=>['1','7'].includes(site))));

            store.currentSite = json.currentSite ? json.currentSite : json.allowedSites[0];
            localStorage.currentSite = json.currentSite ? json.currentSite : json.allowedSites[0];
            // sets default site to myjli.com if allowed
            // localStorage.currentSite = json.allowedSites.includes(1) ? 1 : json.allowedSites[0];

            
            // const folderUrls = {'link': 'https://resources.myjli.com/box/87935', 'image': ''} , {...}];
            // needs to be moved
            store.folderUrls = JSON.stringify(json.folderUrls);
            localStorage.setItem("folderUrls", JSON.stringify(json.folderUrls));

            json.resourceUrl = 'https://resources.myjli.com/box/45478/20881'
            
            store.updateResourceUrl(JSON.stringify(json.resourceUrl))
            localStorage.setItem("resourceUrl", JSON.stringify(json.resourceUrls));


            return loginStatus;

    }

    async fetchRecordingsAlbum(apiUrl) {
        const responseObject = {};
        await fetch(apiUrl, {"method": "GET"})
        .then(response => { 
            if(response.ok){
                return response.json()    
            } else{
                this.loggedIn = false
            }                
        })
        .then(response => {

            responseObject.courseData = {
                apiRootId: response.shareId,
                breadcrumbs: response.breadcrumbs,
                parentApiId: response.backId, //not needed prob
                courseApiId: response.id,
                courseTitle: response.name,
                resourceTitle: response.resourceTitle,
                name: response.name,
                folders: response.content.filter(x => x.type=='folder'),
                tracks: response.content.filter(x=>x.type=='file')

            }
            responseObject.ok = true;

        })
        .catch(() => {
            
            responseObject.ok = false;
            // need to check if cached data, dont want to log out in that case
            responseObject.errorMessage = 'Error Connecting to Server'
        })
        return responseObject;
    }

    async fetchAlbumContents(folderBoxId, courseBoxId) {
        const responseObject = {};
        const apiUrl = this.resource_api_base_url + courseBoxId + '/' + folderBoxId;

        await fetch(apiUrl, {"method": "GET"})
        .then(response => { 
            if(response.ok){
                return response.json()    
            } else{
                this.loggedIn = false
            }                
        })
        .then(response => {
            responseObject.courseData = response;
            responseObject.courseData.folders = response.content.filter(x => x.type=='folder'),
            responseObject.courseData.tracks = response.content.filter(x=>x.type=='file')
            responseObject.ok = true;

        })
        .catch(() => {
            
            responseObject.ok = false;
            // need to check if cached data, dont want to log out in that case
            responseObject.errorMessage = 'Error Connecting to Server'
        })
        return responseObject;
    }


    async fetchWithTimeout(resource, options = {}) {
        const { timeout = 8000 } = options;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(resource, {
          ...options,
          signal: controller.signal  
        }).catch(err => {
            return {ok: false, errorMessage: err}});
        clearTimeout(id);
        return response;
    }

    async fetchCourseData() {
        
        const apiCourseUrls = JSON.parse(store.folderUrls ? store.folderUrls : localStorage.folderUrls);
        const apiCourseUrlKeys = Object.keys(apiCourseUrls);
        const courseDataArray = [];
        if (apiCourseUrls && apiCourseUrlKeys.length>=1) {
            this.loading=true;

            for (let i=0;i<apiCourseUrlKeys.length;i++) {

            const albumApiResponse = await $api.fetchRecordingsAlbum(apiCourseUrls[apiCourseUrlKeys[i]]['link']);
            if (!albumApiResponse.ok) {

                this.errorMessage = albumApiResponse.errorMessage;
                this.loading=false;
                return
            }
            albumApiResponse.courseData.image = apiCourseUrls[apiCourseUrlKeys[i]]['image'];
            courseDataArray.push(albumApiResponse.courseData); 
            }
            }
        return courseDataArray;
    }
}

export const $api = new ApiService();
