<template>
<div class="text-left w-full ">
    <ul class=" text-blue-900 m-auto w-10/12">
    <li v-for="(album, index) in currentAlbum.folders" v-bind:key="index" class=" bg-blue-100 rounded-lg px-2 py-2 border-b border-gray-200 w-full flex items-center  my-3 first:mt-0 last:mb-0 "  @click="setCurrentAlbum(album)">
        <img :src="folderImage(album)" 
        class="h-16 w-16 mr-2 border-r border-gray-300 rounded-md object-cover"
        alt="album image"
        @error="replaceImageSrc"
        />

        <div class="">
        <p class="pl-3 text-md font-medium align-middle">{{ home ? album.resourceTitle : album.name.replace(/\/$/, '') }}</p>
        </div>

    </li>
    <br ><br>
    </ul>
</div>
</template>

  
  
  
<script>
export default {

    
    props: {
        currentAlbum: {
        type: Object,
        required: true
        },
        setCurrentAlbum: {
            type: Function,
            required: true
        },
        replaceImageSrc: {
            type: Function,
            required: true
        },
        home: {
            type: Boolean
        }
    },
    methods: {
        folderImage(album) {
            if (this.currentAlbum.courseImage) {
                return this.currentAlbum.courseImage;
            }
            else if (album.image) {
                return album.image;
            }                
            else {
                return '@/assets/logo.png';     
            } } 
    },
}
</script>