import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'
import Credits from '../views/CreditsView.vue'
import { store } from '@/stores/store'
// vue-router list of routes/pages
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'Homeview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }, 
  {
    path: '/resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "about" */ '../modules/resources/ResourcesHomeView.vue')
  }, 
  {
    path: '/recordings',
    name: 'Recordings',
    component: () => import(/* webpackChunkName: "about" */ '../modules/recordings/RecordingsHomeView.vue')
  }, 
  {
    path: '/credits',
    name: 'Credits',
    component: Credits
  },
  {
    path: '/*',
    name: 'Notfound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



 
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(m => m.meta.requiresAuth) && store.state.token === null) {
//       return next('/login');
//   } else {
//       return next();
//   }
// });

// set this up to work somehow
router.beforeEach(async (to) => {
  console.log('before each route', to);
  if (
    // make sure the user is authenticated
    !store.isAuthenticated &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'Home' && to.name !== 'Login'
  ) {
    console.log('redirecting');
    // redirect the user to the login page
    return { name: 'Home' }
  }
})

export default router
