<!-- originally taken and adapted from https://github.com/muhammadatt/vue-audio-player -->

<template>
    <div id="audio-player-root self-center align-middle h-full mt-20 " >
        <!-- Hide the default audio player -->
        <div >
             <!--  videojs needs video tag -->
            <video
                preload="auto"
                style="display:none"
                ref="player"
                :id="playerid"
                :playback-rate="playbackRate"
                playsinline
            >
        </video>
        </div>
            
        <div id="player-row" class="flex flex-wrap w-full h-full self-center align-middle items-center text-blue-900 " :class="{ 'justify-evenly': !modal, 'justify-around': modal, 'px-4': modal }">

            <div class="flex ">
                <img @click="fullScreenPlayer" :src="imgUrl ? imgUrl : '@/assets/logo.png'" :class="modal ? 'm-auto rounded-md object-cover mb-16 max-h-80 max-w-80 w-72 h-72 self' : 'col-span-1 float-left bottom-0 self-center h-12 w-12 mx-1 rounded-md object-cover '" @error="e=>e.target.src=require('@/assets/logo.png')" />

                <div v-if="name" :class="modal ? 'text-xl py-10 px-4 font-medium text-blue-900 fixed top-14' : 'self-center truncate  font-medium text-lg max-w-xxs sm:max-w-xs'"  @click="fullScreenPlayer">{{ name }}</div>
                
            </div>
            <!-- flex flex-wrap w-full h-full self-center align-middle items-center text-blue-900 -->
        <div class="flex flex-wrap w-1/3 " :class="{ 'justify-evenly': !modal, 'justify-around': modal, 'px-4': modal, 'w-full': modal,  }">
                <button v-show="modal" :disabled="isFirstSong" @click="nextTrack(-1)" class="bg-skip-back bg-no-repeat
                bg-cover w-6 h-6 self-center"  :class="{ 'ml-12': modal, 'opacity-70': isFirstSong}"></button>

                <button @click="changeCurrentTime(15, 'back')"  class="bg-skip-back-15 bg-no-repeat
                    bg-cover w-8 h-8 "></button>

                <div id="button-div" class="  bg-no-repeat
                    bg-cover w-8 h-8 " :class="isPlaying ? 'bg-pause' : 'bg-play'" @click="toggleAudio()" ></div>
                
                
                <button @click="changeCurrentTime(15)" class="bg-skip-15 bg-no-repeat
                bg-cover w-8 h-8 "></button>
                
                <button v-show="modal" :disabled="!audioLoaded" @click="nextTrack(1)" class="bg-skip bg-no-repeat
                bg-cover w-6 h-6 self-center" :class="{ 'mr-0': modal, 'opacity-70': isLastSong}" ></button>
                
                <select v-if="modal" v-model="playbackRate" @change="setPlaybackRate" class="bg-transparent child:bg-blue-50 right-2 top-2 outline-none">
                    <option value="0.5">0.5x</option>
                    <option value="1">1x</option>
                    <option value="1.5">1.5x</option>
                    <option value="2">2x</option>
                    <option value="2.5">2.5x</option>
                </select>
            </div>
           

            <div
                id="progress-bar"
                class="flex-grow w-screen"
            >
                <div class="overlay-container relative w-full h-full">
                    <input
                        v-model="playbackTime"
                        type="range"
                        min="0"
                        :max="audioDuration"
                        step="1"
                        class="slider m-auto my-4"
                        id="my-slider"
                        name="position"
                        @input="slider()"
                    />

                    <div
                        v-show="audioLoaded && modal"
                        class="flex w-full justify-between absolute top-7 bottom-0 right-0 left-0 px-2 pointer-events-none"
                    >
                        <span class="text-sm" style="color: #94bcec" >{{elapsedTimeDisplay}}  </span>
                            
                        <span class="text-sm" style="color: #94bcec" v-html="totalTime()"> </span>
                        
                    </div>
                    
            </div>
            </div>

        </div>
    </div>
</template> 

<script>
import { store } from '@/stores/store';
import videojs from 'video.js';

/* eslint-disable */
export default {
    props: ["url", "name", 'imgUrl', "playerid", "trackPlaying", "fullScreenPlayer", "modal", "skipTrack","isFirstSong", "isLastSong"],
    /**
     * playbackTime = local const that syncs to audio.currentTime
     * audioDuration = duration of audio file in seconds
     * isPlaying = boolean (true if audio is playing)
     *
     **/
    data() {
        return {
            playbackTime: 0,
            audioDuration: null,
            audioLoaded: false,
            isPlaying: false,
            elapsedTimeDisplay: '00:00',
            listenerActive: false,
            playbackRate: 1,
            player: null,
        };
    },
    methods: {
        //Set the range slider max value equal to audio duration
        initSlider() {
            if (this.player) {
                this.audioDuration = store.audioDuration = Math.round(this.player.duration());
            }
        },
        nextTrack(numberSkip=1) {
            this.endListener();
            if (this.isFirstSong && numberSkip <= 0) return;
            if (this.isLastSong && numberSkip >= 0) return;
            
            this.skipTrack(numberSkip);
        },
        //Convert audio current time from seconds to hour:min:sec display
        convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                const hours = seconds / 3600;
                const minutes = (seconds % 3600) / 60;
                const returnTime = seconds>=3600 ? [hours, minutes, seconds % 60].map(format).join(":") : [minutes, seconds % 60].map(format).join(":");
                return returnTime;
        },
        //Show the total duration of audio file
        totalTime() {
            if (this.player) {
                const seconds = this.player.duration();
                return this.convertTime(seconds);
            } else {
                return '00:00';
            }
        },
        //Playback listener function runs every 100ms while audio is playing
        playbackListener(e) {
            //Sync local 'playbackTime' const to audio.currentTime and update global state
            this.playbackTime = this.player.currentTime();
            store.currentTrackTime = this.player.currentTime;
            localStorage.setItem('currentTrackTime', this.player.currentTime());
            this.elapsedTimeDisplay = this.convertTime(this.player.currentTime());

            this.slider()

            // Add listeners for audio pause and audio end events
            this.player.on("ended", this.endTrack);
        },
        endTrack(){
            this.nextTrack(1);
        },
        //Function to run when audio play reaches the end of file
        endListener() {
            this.$parent.$emit('playStatus', false);
            this.listenerActive = false;
            localStorage.removeItem('currentTrack');
            localStorage.removeItem('currentTrackTime');
            store.currentTrackTime = null;
            store.currentTrack = null;
        },
        setTrackTime(targetSeconds=0) {
            this.player.currentTime(targetSeconds);
            this.playbackTime = targetSeconds;
            localStorage.setItem('currentTrackTime', targetSeconds);
            store.currentTrackTime = targetSeconds;
            this.elapsedTimeDisplay = this.convertTime(targetSeconds)
            this.slider(targetSeconds)
        },
        toggleAudio() {
            if (this.player.paused()) {
                if (this.player.ended()) {
                    this.setTrackTime()
                }
                this.player.play();
                this.isPlaying = true;
                this.$parent.$emit('playStatus', true)
            } else {
                this.player.pause();
                this.isPlaying = false;
                this.$parent.$emit('playStatus', false);
            }
        },
        changeCurrentTime(secondsChange, direction='forward') {
            let newTime = direction=='back' ? this.player.currentTime() - secondsChange : this.player.currentTime() + secondsChange;
            if (newTime>=this.audioDuration) {
                this.setTrackTime(this.audioDuration)
            } 
            else if (newTime<=0) {
                this.setTrackTime(0)
            }
            else {
                this.setTrackTime(newTime)
            }
        },
        setPlaybackRate() {         
            this.player.playbackRate(this.playbackRate);
            store.playbackRate = this.playbackRate;
            localStorage.setItem('playbackRate', this.playbackRate)
        },         
        slider(value=null){
            const mySlider = document.getElementById("my-slider");
            value = value ? value : mySlider.value;
            const valPercent = (value / this.audioDuration)*100;
            mySlider.style.background = `linear-gradient(to right, #1e40af ${valPercent}%, #d5d5d5 ${valPercent}%)`;
        }    
    },
    
    mounted: function() { 
        
        
        store.currentTrack = localStorage.currentTrack && !store.currentTrack ? localStorage.currentTrack : store.currentTrack;  
        
        store.audioDuration = localStorage.audioDuration && !store.audioDuration ? localStorage.audioDuration : store.audioDuration;

        store.currentTrackTime = localStorage.currentTrackTime && !store.currentTrackTime ? localStorage.currentTrackTime : store.currentTrackTime;

        store.playbackRate = localStorage.playbackRate ? localStorage.playbackRate : store.playbackRate;
        
      // nextTick code will run only after the entire view has been rendered
      this.$nextTick(function() {
        this.player = videojs(this.$refs.player, {
        autoplay: true,
        audioOnlyMode: true,
        controls: false,
        nativeControlsForTouch: false, // not sure what this does, might affect ios differently.
        sources: [
          {
            src:
              this.url,
              type: 'audio/mpeg'
          }
        ]}, ()=>{
            this.audioLoaded=true;
            
            if (this.trackPlaying) {
                this.isPlaying = true;
                
            }
        })
        this.player.on('durationchange', this.initSlider);

        this.elapsedTimeDisplay =  store.currentTrackTime && store.currentTrackTime>0 ? this.convertTime(store.currentTrackTime) : '00:00';
         
        this.audioDuration = store.audioDuration && Math.round(store.audioDuration)>0 ? Math.round(store.audioDuration) : null;

        this.playbackTime = store.currentTrackTime && parseInt(store.currentTrackTime)>0 ? parseInt(store.currentTrackTime) : 0;
        this.slider(this.playbackTime)
        this.playbackRate = store.playbackRate && parseInt(store.playbackRate) ? parseInt(store.playbackRate) : 1;

        this.player.playbackRate(this.playbackRate);
        // Wait for audio to begin play, then start playback listener function
        this.$watch("isPlaying",function() {
          if(this.isPlaying) {
            if (store.currentTrackTime && store.currentTrackTime>0) {
                this.player.currentTime(store.currentTrackTime);
            }

            //prevent starting multiple listeners at the same time
            if(!this.listenerActive) {
              this.listenerActive=true;
              this.player.on("timeupdate",this.playbackListener);
            }
          } 
        });
        //Update current audio position when user drags progress slider
        this.$watch("playbackTime",function() {
            const diff=Math.abs(this.playbackTime-this.player.currentTime());
        
            //Throttle synchronization to prevent infinite loop between playback listener and this watcher
            if(diff>0.01) {
                this.setTrackTime(this.playbackTime)
            }
        });
        
      });
      
    },
    beforeUnmount(){
        if (this.player){
            this.player.dispose();
        }
    }
};
</script>

<style scoped >
input[type="range"]{
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 80%;
    height: 8px;
    background-color: #d5d5d5;
    border-radius: 8px;
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    height: 8px;
}
input[type="range"]::-moz-track{
    -moz-appearance: none;
    height: 8px;
} 
input[type="range"]::-ms-track{
    appearance: none;
    height: 8px;
} 
input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-color: #1e40af;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    border: none;
}
input[type="range"]::-moz-range-thumb{
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-color: #1e40af;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    border: none;
}
input[type="range"]::-ms-thumb{
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: #1e40af;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    border: none;
}
input[type="range"]:active::-webkit-slider-thumb{
    background-color: #ffffff;
    border: 3px solid #1e40af;
}

</style>

