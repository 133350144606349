import { createApp } from 'vue'
// import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './index.css'
// const pinia = createPinia()

// .use(pinia)
createApp(App).use(router).mount('#app')
