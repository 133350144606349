<!-- taken from https://github.com/akahon/vue-sidebar-menu-akahon -->

<template>
  
  <BackgroundOverlay v-if="isOpened"  @click.prevent="menuDisplay(false)"/>

  <div
    class="sidebar bg-blue-100 m-0 p-0 box-border flex flex-col fixed right-0 top-0 h-full min-h-min w-0 z-50 "
    :class="isOpened ? 'open' : ''"
    :style="cssVars"
  >
    <div
      class="logo-details mt-2 mr-4 mb-0 ml-4 h-16 flex items-center relative"
      
    >
      <img
        src="@/assets/logo.png"
        alt="menu-logo"
        class="menu-logo icon w-8 mx-1 my-0 "
        @click="logoClick"
      >
      <div class="logo_name">
        {{ menuTitle }}
      </div>
      
      <i
        v-if="isOpened"
        class="bx bx-menu-alt-right"
        id="btn"
        @click="menuDisplay(false)"
      />
    </div>

    <div style="display: flex ; flex-direction:column; justify-content: space-between; flex-grow: 1; max-height: calc(100% - 60px); ">
      <div
        id="my-scroll"
        style="margin: 6px 14px 0 14px;"
      >
        <ul
          class="nav-list"
          style="overflow: visible;"
        >
        <li>
              <span class="tooltip">Switch Site</span>
            </li>
          <li >
            <a class="font-medium" >
                <i
                  class="bx bx-chevron-down"
                />
                <span class="links_name">
                  <select v-model="currentSite" @change="handleSiteSwitch" class="font-medium">
                <option v-for="site in allowedSites" :key="site" :value="site" >{{ siteName(site) }}</option>
              </select>
            </span>
            </a>
              <span class="tooltip">Switch Site</span>
              
            </li>






            <li>
            <span class="tooltip">resources</span>
          </li>
          <li @click="menuDisplay(false)">
            <router-link to="/resources" >
                <i
                  class="bx bx-briefcase"
                />
                <span class="links_name">resources</span>
            </router-link>
              <span class="tooltip">resources</span>
          </li>
          
          

          <li>
            <span class="tooltip">recordings</span>
          </li>
          <li @click="menuDisplay(false)">
            <router-link to="/recordings" >
                <i
                  class="bx bx-equalizer"
                />
                <span class="links_name">recordings</span>
            </router-link>
              <span class="tooltip">recordings</span>
          </li>
          
          

          <!-- <li>
            <span class="tooltip">downloads</span>
          </li>
          <li @click="menuDisplay(false)">
            <router-link to="/downloads" >
                <i
                  class="bx bx-disc"
                />
                <span class="links_name">downloads</span>
            </router-link>
              <span class="tooltip">downloads</span>
          </li>
          
          

          <li>
              <span class="tooltip">credits</span>
            </li>
          <li @click="menuDisplay(false)">
            <router-link to="/credits" >
                <i
                  class="bx bx-info-circle"
                />
                <span class="links_name">credits</span>
            </router-link>
              <span class="tooltip">credits</span>
          </li> -->


        </ul>
      </div>
      <div class="ml-3.5">

        <li>
              <span class="tooltip">Logout</span>
            </li>
          <li @click="logoutFunc">
            <a >
                <i
                  class="bx bx-user"
                />
                <span class="links_name">Logout</span>
            </a>
              <span class="tooltip">Logout</span>
            </li>

      </div>
    </div>
  </div>
</template>

<script>
  import { $localStorage } from '../services/localStorage'
  import { siteIdsToNames } from '../services/functions'
  import router from '@/router'
import BackgroundOverlay from './BackgroundOverlay.vue'
  export default {
    name: "MenuSide",
    props: {
        isMenuOpen: {
            type: Boolean,
            default: true,
        },
        logoutFunc: {
            type: Function
        },
        menuDisplay: {
            type: Function
        },
        menuTitle: {
            type: String,
            default: "MyJLI",
        },
        switchSite: {
            type: Function
        }
    },
    data() {
        return {
            isOpened: false,
            currentSite: siteIdsToNames,
            allowedSites: []
        };
    },
    mounted() {
        this.isOpened = this.isMenuOpen;

        // switch to store?
        this.currentSite = $localStorage.getCurrentSite();
        this.allowedSites = $localStorage.getAllowedSites();
    },
    updated() {
        this.isOpened = this.isMenuOpen;
    },
    methods: {
        logoClick() {
            router.push({ name: "Home" });
            this.menuDisplay(false);
        },
        handleSiteSwitch(event) {
            // if (!typeof(Number.isInteger(event.target.value))) return;
            this.switchSite(event.target.value);
            // this.menuDisplay(false);
        },
        siteName(siteId) {
            return siteIdsToNames[siteId];
        }
    },
    computed: {
        cssVars() {
            return {
                // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
                "--bg-color": "#DBEAE7",
                "--secondary-color": "#155e75",
                "--logo-title-color": "#155e75",
                "--icons-color": "#155e75",
                "--items-tooltip-color": "yellow",
                "--serach-input-text-color": "#155e75",
                "--menu-items-hover-color": "#155e75",
                "--menu-items-text-color": "#155e75",
                "--menu-footer-text-color": "#155e75",
            };
        },
    },
    watch: {
        isOpened() {
        }
    },
    components: { BackgroundOverlay }
}
</script>

<style scoped>
select {
    border:none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
    background-color: rgba(0, 0, 0, 0.0);
}

  body {
    transition: all 0.5s ease;
  }
  .sidebar {
    transition: all 0.5s ease;
  }
  .sidebar.open {
    width: 250px;
  }
  .sidebar .logo-details {
    /* height: 60px; */
    /* display: flex;
    align-items: center;
    position: relative; */
  }
  .sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .sidebar .logo-details .logo_name {
    color: var(--logo-title-color);
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .sidebar.open .logo-details .icon,
  .sidebar.open .logo-details .logo_name {
opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
  color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 20px;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: var(--items-tooltip-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip {
  display: none;
}
.sidebar input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: var(--secondary-color);
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  /* background: var(--bg-color); */
}
.sidebar li a:hover {
  background: var(--menu-items-hover-color);
}
.sidebar li a .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: var(--bg-secondary-color);
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar div img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.my-scroll-active {
  overflow-y: auto;
}
#my-scroll {
  overflow-y: auto;
  height: calc(100% - 60px);
}
#my-scroll::-webkit-scrollbar{
  display:none;
}
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
</style>