<template>
  <div class="mt-5 text-blue-800">
      {{ loadingMessage }}
      <div class="flex justify-center items-center mt-2">
        
        <div class="spinner-border animate-spin inline-block w-8 h-8 border-2 rounded-full align-middle border-b-4 border-b-blue-400" role="status"></div>
      </div>
  </div>
  
</template>
  
  <script>
  export default {
    name: 'NavBar',
    props: ['loadingMessage']
  }
  </script>