<template class=" text-green-300 ">
  <LoadingSpinner v-if="loading" loading-message="fetching new recordings"/>
  
  <p v-if="errorMessage" class="mt-5 text-red-400">{{ errorMessage }}</p>

  <div class="recordings text-blue-900 pb-16 " v-if="coursesData && !songModalOpen"  >

    <div class="menu flex justify-start px-4 mt-14" v-if="!home">

      <button v-if="currentAlbum.breadcrumbs" @click="resetRecordings()" class="inline-block px-3 py-2 my-2 mx-0.5 border border-blue-100 text-blue-900 font-medium text-lg leading-tight uppercase rounded-lg hover:bg-blue-200 hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out bx bx-home" ></button>

      <button class="inline-block px-3 py-2 my-2 mx-0.5 border border-blue-100 text-blue-900 font-medium text-lg leading-tight uppercase rounded-lg hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out bx bx-arrow-back" v-if="currentAlbum.breadcrumbs && currentAlbum.parentAlbum.parentAlbum" @click="goToParentAlbum()"></button>
    </div>

    
    
    <p class="mt-0 mb-2 text-blue-900 text-2xl pt-4 ml-8 text-left break-words mr-5">{{currentAlbum.name}}</p>

    <FoldersList  v-if="currentAlbum.folders" :home="home" :replace-image-src="replaceImageSrc" :set-current-album="setCurrentAlbum"  :current-album="currentAlbum" />

    <TracksList  v-if="currentAlbum.tracks" :replace-image-src="replaceImageSrc" :select-song="selectSong" :current-album="currentAlbum" />
  </div>

  <div class="audio-players" v-if="currentSongData ">
    <TrackPlayer :class="!songModalOpen ? 'fixed bottom-0 w-full ' : ''" @close="songModalOpen=false" :song-data="currentSongData" :close-track="closeTrack"  :full-screen-player="openModal" :track-playing="trackPlaying"  @playStatus="pausePlayTrack"  :modal="songModalOpen" :skip-track="skipTrack" :key="trackKey"/>
  </div>
  
</template>


<script>
import { $api } from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import FoldersList from './components/FoldersList.vue'
import TracksList from './components/TracksList.vue'
import TrackPlayer from './components/TrackPlayer.vue'
import { siteIdsToNames } from '@/services/functions'
import { store } from '@/stores/store'

export default {
name: 'RecordingsHomeView',
data() {
  return {
    currentAlbum: {
      name: siteIdsToNames[localStorage.currentSite] || 'Home',
      folders: null, // courseDataArray.folders
      tracks: null,
      courseImage: null, // default to jli logo
      breadcrumbs: null,
      parentAlbum: null
    },
    songModalOpen: false,
    currentSongData: false,
    trackPlaying: false,
    errorMessage: '',
    coursesData: [],
    home: true,
    loading: false,
    trackKey: 1
  }
},

async mounted() {
  if (localStorage.getItem('currentTrack')) {
    // improves experience - always have recent track even if offline etc
    this.currentSongData = JSON.parse(localStorage.getItem('currentTrack'))
  }
  this.resetCoursesData()

},
components: {
    LoadingSpinner,
    FoldersList,
    TracksList,
    TrackPlayer
},
methods: {
  goToParentAlbum() {
    // does not work to go to homepage
    this.currentAlbum = this.currentAlbum.parentAlbum;
  },

  async setCurrentAlbum(album, breadcrumb=false) {
    if (!album && !breadcrumb) {
      this.errorMessage = 'there was an error with this course, please try again or reload';
      return
    }

    if (this.home) {
      const newCurrentAlbum = {
        name: album.breadcrumbs[0].name.replace(/\/$/, ''), //removes trailing slash
        folders: album.folders && album.folders .length>=1 ? album.folders : null,
        tracks: album.tracks ? album.tracks : null,
        courseImage: album.image ? album.image : "@/assets/logo.png",
        courseApiId: album.apiRootId,
        breadcrumbs: album.breadcrumbs,
        parentAlbum: this.currentAlbum
      };
      this.currentAlbum = newCurrentAlbum;
      this.home=false;

    } else {

      const response = await $api.fetchAlbumContents(album.id, this.currentAlbum.courseApiId);
      if (!response.ok) {
        this.errorMessage = response.errorMessage;
        return;
      }
      const newCurrentAlbum = {
        name: response.courseData.name.replace(/\/$/, ''), //removes trailing slash
        folders: response.courseData.folders&&response.courseData.folders.length>=1 ? response.courseData.folders : null,
        tracks: response.courseData.tracks ? response.courseData.tracks : null,
        courseImage: this.currentAlbum.courseImage,
        courseApiId: this.currentAlbum.courseApiId,
        breadcrumbs: response.courseData.breadcrumbs,
        parentAlbum: this.currentAlbum
      }
      this.currentAlbum = newCurrentAlbum;
    }
    return;
  } ,

  resetRecordings() {
    
    this.currentAlbum = {
      name: siteIdsToNames[localStorage.currentSite] || 'Home',
      folders: this.coursesData,
      tracks: null,
      courseImage: null,
      breadcrumbs: null,
      parentAlbum: null
    }
    this.home = true;
  },
  async selectSong(track, index, albumData=null) {
    if (this.loading) return;
    this.loading = true;
    await this.closeTrack();
    track.image = this.currentAlbum.courseImage;
    track.trackFolderIndex = index;
    if (!albumData) {
      // if called from within correct album
      track.albumLength = this.currentAlbum.tracks.length;
      const albumData = JSON.stringify(this.currentAlbum.tracks);
      track.albumData = albumData;
    } else {
      track.albumLength = albumData ? albumData.length : null;
      track.albumData = albumData ? JSON.stringify(albumData) : null;
    }
    track.image = this.currentAlbum.courseImage;
    this.currentSongData = track;
    this.trackKey+=1;
    this.trackPlaying = true;
    this.loading = false;
    store.currentTrack = JSON.stringify(track);
    store.currentTrackTime = 0;
    localStorage.setItem('currentTrack', JSON.stringify(track))
    localStorage.setItem('currentTrackTime', 0)
  },
  skipTrack(skipNumber = 1) {
    if (typeof skipNumber !== 'number') skipNumber = 1;
    const newIndex = this.currentSongData.trackFolderIndex + skipNumber;
    const albumData = this.currentSongData.albumData ? JSON.parse(this.currentSongData.albumData) : null;
    this.closeTrack();
    albumData ? this.selectSong(albumData[newIndex], newIndex, albumData) : '';
  },
  openModal() {
    this.songModalOpen = true;
  },
  async closeTrack(){
    this.currentSongData = false;
    this.trackPlaying = false;
    store.currentTrack = null;
    store.currentTrackTime = 0;
    localStorage.removeItem('currentTrack')
    localStorage.removeItem('currentTrackTime')
  },
  pausePlayTrack(playStatus=true) {
    this.trackPlaying = playStatus;
  },
  replaceImageSrc(event) {
    event.target.src = require('@/assets/logo.png');
  },
  async resetCoursesData() {
    
    const courseDataArray = await $api.fetchCourseData();
    if (!courseDataArray) {
      this.errorMessage = 'No Recordings Found';
      return;
    }
    this.coursesData = courseDataArray;
    this.currentAlbum = {
      name: siteIdsToNames[localStorage.currentSite] || 'Home',
      folders: courseDataArray,
      tracks: null,
      courseImage: null,
      breadcrumbs: null,
      parentAlbum: null
    }
    this.loading=false
  }
},
}
</script>