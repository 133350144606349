<template>
<ul class="track-list px-4 mb-20 divide-y divide-slate-200 text-blue-900 text-left w-12/12" >
    <li v-for="(track, index) in currentAlbum.tracks" :key="index" @click="selectSong(track, index)" class="flex py-4 first:pt-0 last:pb-0 mb-6 align-middle items-center px-2">
        <img :src="currentAlbum.courseImage?currentAlbum.courseImage:'@/assets/logo.png'" 
            class="h-11 w-11 l-2 border-r border-gray-300 rounded-md object-cover"
            alt="album image"
            @error="replaceImageSrc"/>

        <div class="w-10/12 ">
            <p class="ml-4  text-sm">{{ track.name }}</p>
            <p class="ml-4 font-extralight text-xs truncate text-blue-800">{{ currentAlbum.name /**change to root course name/artist name */}}</p>
        </div>
    </li>
</ul>
</template>

    
    
    
<script>
export default {
    
    props: {
        currentAlbum: {
        type: Object,
        required: true
        },
        selectSong: {
            type: Function,
            required: true
        },
        replaceImageSrc: {
            type: Function,
            required: true
        }
    },
}
</script>