<template>
  <section class="h-screen">
  <div class="px-6 h-full text-gray-800">
    <div
      class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
    >
      <div
        class="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 md:mb-0 self-end sm:self-center md:self-end lg:self-center"
      >
        <img
          src="../assets/logo.png"
          class="w-10/12 m-auto mb-0 md:w-6/12"
          alt="Sample image"
        />
      </div>
      <div class="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
        <form>
          <div class="flex flex-row items-center justify-left lg:justify-start">
            <p class="text-lg mb-0 mr-4">Sign in </p>
            
            <div class=' text-red-400' v-if="errorMessage">{{errorMessage}}</div>
          </div>

          <!-- Email input -->
          <div class="mb-6 mt-4">
            <input
              type="text"
              class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="usernameForm"
              placeholder="username"
              autocomplete="username"
              v-model="username"
            />
          </div>

          <!-- Password input -->
          <div class="mb-6">
          <button type="button" class="bx bx-sm  text-gray-500 float-right relative top-9 right-2" :class="{'bx-show': showPassword, 'bx-hide': !showPassword}"  @click="showPassword=!showPassword"></button>
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="passwordForm"
              placeholder="password"
              autocomplete="current-password"
              v-model="password"
            />
            
          </div>



          <div class="text-center lg:text-left">
            <button
              type="submit"
              class="inline-block px-7 py-3 bg-blue-800 text-white font-medium text-sm leading-snug uppercase rounded-md w-full shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-900 active:shadow-lg transition duration-150 ease-in-out"
              @click.prevent="submitLogin"
            >
              Login
            </button>

            <a href="https://www.myjli.com/index.html?task=forgot_password" class="relative top-5 text-blue-400">reset password</a>
          </div>
        
        </form>
      </div>
    </div>
  </div>
</section>
</template>


<script>
import { $api } from "@/services/api";
  export default {
    props: [
      'setLoggedIn', 'errorMsg'
    ],
    mounted() {
      this.errorMessage = this.errorMsg
    },
    data() {
        return {
            username: '', // need method to check that
            password: '',
            errorMessage: null,
            showPassword: false
        };
    },
    methods: {
      async submitLogin() {
        this.errorMessage = null
        const loginStatus = await $api.login(this.username,this.password)
        if (!loginStatus.success) {
          this.errorMessage = loginStatus.errorMessage
        } else {
          this.setLoggedIn(true)
        }
      }
    }
}
</script>
