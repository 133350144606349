class LocalStorageService {
    // put all local storage functions here - for accessing locally saved data

    getAllowedSites() {
        if (!localStorage.allowedSites || localStorage.allowedSites === []) {
            // TODO logout
            return false
        }
        return JSON.parse(localStorage.getItem("allowedSites"))
    }
    getCurrentSite() {
        if (!localStorage.currentSite) {
            // TODO logout
            return false
        }
        return localStorage.getItem("currentSite")
    }
}

export const $localStorage = new LocalStorageService();
