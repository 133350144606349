import { reactive } from 'vue'

export const store = reactive({
  currentSite: 1,
  resourceUrl: 'https://www.chabad.org',
  isAuthenticated: false,
  currentTrack: null,
  currentTrackTime: 0,
  audioDuration: 0,

  updateResourceUrl(/*newUrl*/) {
    // this.resourceUrl = newUrl;
    this.resourceUrl = {"VikiTora 5783":{"link":"https://resources.myjli.com/box/58899"},"Booksmart":{"link":"https://resources.myjli.com/box/07284"},"Fall: My G-d":{"link":"https://resources.myjli.com/box/62134"},"Spring: Jewpernatural":{"link":"https://resources.myjli.com/box/08271"},"Outsmarting Antisemitism":{"link":"https://resources.myjli.com/box/28853"},"Mediation from Sinai":{"link":"https://resources.myjli.com/box/96865"},"Beyond Right":{"link":"https://resources.myjli.com/box/60624"},"Judaism: The Soundtrack":{"link":"https://resources.myjli.com/box/85748"},"The Soundtrack":{"link":"https://resources.myjli.com/box/11642"},"Journey of the Soul":{"link":"https://resources.myjli.com/box/01457"},"General":{"link":"https://resources.myjli.com/box/29507"},"Oasis in Time":{"link":"https://resources.myjli.com/box/23215"},"Secrets of the Bible":{"link":"https://resources.myjli.com/box/39157"},"This Can Happen":{"link":"https://resources.myjli.com/box/19750"},"Worrier to Warrior":{"link":"https://resources.myjli.com/box/11342"},"Judaism's Gifts to the World":{"link":"https://resources.myjli.com/box/85090"}}
  },
  clear() {
    // reset everything
    this.resourceUrl = '',
    this.isAuthenticated = false,
    this.currentTrack = null, 
    this.currentTrackTime = 0
  }
})