<!-- TODO update to show nomral popup to tell ios users how to install pwa -->

<template>
    <div v-if="showInstallMessage" class="absolute top-3 m-auto left-0 right-0 z-10 w-4/6  rounded-md p-2 text-blue-900 bg-blue-200 text-sm">Install App by pressing "Share" icon then "Add to home"</div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
    showInstallMessage: false
        
    }
  },
  mounted(){
    // ensure only shows in site not app
    // Detects if device is on iOS 
    const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
        this.showInstallMessage=true;
    }

    setTimeout(function () { this.showInstallMessage=false }.bind(this), 7000)
  }
}
</script>