<template>
  <nav class="
  fixed top-0 
  w-full
  flex flex-wrap
  items-center
  justify-between
  py-4 
  shadow-sm
  navbar navbar-expand-lg navbar-light bg-blue-50
  ">
  <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
    <div><!-- added div puts menu on right side --></div>
  <button @click="menuDisplay(true)"
   class="
      bg-menu-burger
      navbar-toggler
      text-blue-500
      border-0
      hover:shadow-none hover:no-underline
      py-2
      px-2.5
      bg-transparent
      focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
    " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  
  </button>

  </div>
</nav>

</template>

<script>
export default {
  name: 'NavBar',
  props: {
    menuDisplay: Function
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  
}
</style>
