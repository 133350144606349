<template>
  
  <div class="page-layout max-h-screen h-screen w-screen overflow-x-hidden overflow-y-auto bg-blue-50">
    <IosInstallPopup/>
    <div v-if="loggedIn">
      <div v-if="isLoading">
        <div class="flex justify-center items-center align-middle h-full mt-60">
          <div class="spinner-border animate-spin inline-block w-16 h-16 border-2 rounded-full align-middle border-b-4 border-b-blue-400" role="status">
            
          </div>
        </div>
      </div>
      <div v-else>
        
        <MenuSide :is-menu-open="menuOpen" :menu-display="menuDisplay" :logout-func="logoutFunc" :switch-site="switchSite" />
        <NavBar :menu-display="menuDisplay" />
        <router-view class="mt-16 h-screen" />
      </div>
    </div>
    <div v-else class="h-full">
      <LoginView :set-logged-in="loginFunc" :error-msg="errorMessage"/>
      <!-- handle switching sites -->
    </div>
  </div>
</template>


<script>
import LoginView from './views/LoginView.vue';
import NavBar from './components/NavBar.vue';
import { $api } from "@/services/api";
import MenuSide from './components/MenuSide.vue';
import IosInstallPopup from './components/iosInstallPopup.vue';

  export default {
    setup() {
    },
    data() {
        return {          
            loggedIn: false, // need method to check this
            isLoading: false,
            userId: null,
            errorMessage: null,
            menuOpen: false
        };
    },
    methods: {
        loginFunc(status) {
          this.loggedIn = status;

        },
        logoutFunc() {
          this.loggedIn = false;
          this.menuOpen = false;
          $api.logout();
        },
        menuDisplay(displayStatus) {
          this.menuOpen = displayStatus;
        },
        async switchSite(siteId) {
          console.log('switching site', siteId);
          this.isLoading = true;
          this.userId = localStorage.userId;
          const response = await $api.authenticateUser(this.userId, siteId)
          if (!response.success) {
            this.isLoading = false;
            this.errorMessage = response.errorMessage;
            return
          }
          console.log('finito!');
          this.isLoading = false;
        }
    },
    async mounted() { 
      
        if (!localStorage.userId) {
          this.loggedIn = false
          return
        }

        this.loggedIn=true;
        this.isLoading=true;
        this.userId = localStorage.userId;
        
        // this needs to work if app has no internet access also... - TODO
        const currentSite = localStorage.currentSite ? localStorage.currentSite : 1;
        const response = await $api.authenticateUser(this.userId, currentSite)
        // api call to verify
        if (!response.success) {
          this.loggedIn = false;
          this.isLoading=false;
          this.errorMessage=response.errorMessage;
          return
        }

        this.isLoading=false;
        this.loggedIn=true;
    },
    beforeUpdate() {
      // trying to get it to work offline - a bigger project than this
        // if (this.fetchedNewRecordings || !navigator.onLine) return
    },
    components: { LoginView, NavBar, MenuSide, IosInstallPopup }
}
</script>

<style>
/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');

#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  /* leaving for future app pages dev */
  /* display: none; */
  padding: 30;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
