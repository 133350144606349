const siteIdsToNames = {
    1:'Flagship',
    2:'Torah Studies',
    3:'Sinai Scholars',
    4:'Backend',
    5:'JNet',
    6:'JLI Teens',
    7:'Rosh Chodesh Society',
    8:'myShiur',
    9:'Beis Hamikdosh'
}   

//Convert audio current time from seconds to hour:min:sec display
function convertTime(seconds) {
    if(seconds<=0 || typeof(seconds) !== 'number') return '00:00';

    const format = val => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;
    const returnTime = seconds>=3600 ? [hours, minutes, seconds % 60].map(format).join(":") : [minutes, seconds % 60].map(format).join(":");
    return returnTime;
}

export {siteIdsToNames, convertTime};
// export { siteIdsToNames, CryptoJSAesEncrypt, encryptUsingAES256 };

