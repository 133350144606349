<template>
  <div class="home">
    <RecordingsHomeView />
  </div>
</template>

<script>
// @ is an alias to /src
import RecordingsHomeView from '@/modules/recordings/RecordingsHomeView.vue';

export default {
  // future homepage for multipage app, currently redirecting to recordings homepage because nothing else in app
  name: 'HomeView',
  components: {
    RecordingsHomeView,
},
}


</script>
